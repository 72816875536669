import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ProjectMainLayout } from 'layouts/ProjectMainLayout';
import { PROJECTS } from 'constants/projects';
import { GridFour } from 'components/GridFour';

export const ItaluxView = () => {
    const images = useStaticQuery(graphql`
        query ItaluxImages {
            upperLeft: file(name: { eq: "italux-upper-left" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            upperRight: file(name: { eq: "italux-upper-right" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            lowerLeft: file(name: { eq: "italux-lower-left" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            lowerRight: file(name: { eq: "italux-lower-right" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
        }
    `);

    return (
        <ProjectMainLayout projectName={PROJECTS.italux.name}>
            <GridFour {...images} projectName={PROJECTS.italux.name} />
        </ProjectMainLayout>
    );
};
