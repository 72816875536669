import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { GridWrapper } from 'src/components/GridWrapper';

const SRight = styled.div`
    margin-left: 0.8rem;
    flex: 1;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px), (orientation: portrait) {
        margin-top: 0.8rem;
        margin-left: unset;
    }
`;

const SLeft = styled.div`
    display: flex;
    flex-direction: column;
    ${({ widerLeft }) =>
        widerLeft
            ? `width: 63%; 
               @media (max-width: 768px), (orientation: portrait) {
                    width: 100%;
                }`
            : 'flex: 1'}
`;

const SUpperImage = styled(GatsbyImage)`
    margin-bottom: 0.8rem;
    height: 43%;
`;

const SLowerImage = styled(GatsbyImage)`
    height: 55%;
    flex: 1;
`;

export const GridFour = ({ upperLeft, upperRight, lowerLeft, lowerRight, widerLeft, projectName }) => {
    return (
        <GridWrapper>
            <SLeft widerLeft={widerLeft}>
                <SUpperImage image={getImage(upperLeft)} alt={projectName} />
                <SLowerImage image={getImage(lowerLeft)} alt={projectName} />
            </SLeft>
            <SRight>
                <SUpperImage image={getImage(upperRight)} alt={projectName} />
                <SLowerImage image={getImage(lowerRight)} alt={projectName} />
            </SRight>
        </GridWrapper>
    );
};
